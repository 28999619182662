<template>
    <div class="home">
        <div class="tilte_btn">
            <span class="lineY"></span>
            <span><h1>农村劳动力转移统计</h1></span>
        </div>
        <nav class="nav_sech">
            <el-switch
                @change="tabChange"
                v-model="switchVale"
                active-text="图表综合统计"
                inactive-text="表格统计明细">
            </el-switch>
            <el-button type="warning" @click="sechInfo = true" v-if="!switchVale"
            style="margin-left: 15px;" size="mini">多维度统计配置</el-button>
        </nav>
        <component :is="comName" :comParam="comParam"></component>
        <el-drawer
            :with-header="false"
            :visible.sync="sechInfo"
            :direction="direction">
            <main class="finds">
                <div style="display: flex;align-items: center;justify-content: space-between;">
                    <h2>筛选条件</h2>
                    <!-- <el-button size="mini" type="info">确定</el-button> -->
                </div>
                <div class="m_top10">
                    <span class="ent_del">搜索关键词：</span>
                    <el-input placeholder = "请输入关键词" v-model="comParam.keywords" style="width: 200px" size="mini"></el-input>
                </div>
                <div class="m_top10">
                    <span class="ent_del">搜索关键词：</span>
                    <el-input placeholder = "安全生产保护措施,模糊查询" v-model="comParam.safeProtectStep" style="width: 200px" size="mini"></el-input>
                </div>
                <div class="m_top10">
                    <span class="ent_del">期望新资：</span>
                    <el-select v-model="comParam.ittIndex" placeholder="请选择" clearable
                    style="width:200px" size="mini" @change="chanittIttm">
                        <el-option
                            v-for="(item, index) in salaryArr"
                            :key="index"
                            :label="item.label"
                            :value="index">
                        </el-option>
                    </el-select>
                </div>
                <div class="m_top10">
                    <span class="ent_del">新资待遇：</span>
                    <el-select v-model="comParam.salaryIndex" placeholder="请选择" clearable
                    style="width:200px" size="mini" @change="chanSalary">
                        <el-option
                            v-for="(item, index) in salaryArr"
                            :key="index"
                            :label="item.label"
                            :value="index">
                        </el-option>
                    </el-select>
                </div>
                <div class="m_top10">
                    <span class="ent_del">人员类型：</span>
                    <el-select v-model="comParam.laborForceType" placeholder="请选择" clearable
                    style="width:200px" size="mini">
                        <el-option label="贫困劳动力" value="1"></el-option>
                        <el-option label="享受劳务补贴" value="2"></el-option>
                    </el-select>
                </div>
                <div class="m_top10" v-if="comParam.laborForceType ==2">
                    <span class="ent_del">劳务补贴类型：</span>
                    <el-select v-model="comParam.laborSubsidyType" placeholder="请选择" clearable
                    style="width:200px" size="mini">
                        <el-option label="交通补贴" value="1"></el-option>
                        <el-option label="劳务补贴" value="2"></el-option>
                        <el-option label="生获补贴" value="3"></el-option>
                    </el-select>
                </div>
                <div class="m_top10">
                    <span class="ent_del">性别：</span>
                    <el-select v-model="comParam.sex" placeholder="请选择" clearable
                    style="width:200px" size="mini">
                        <el-option label="男" value="1"></el-option>
                        <el-option label="女" value="2"></el-option>
                    </el-select>
                </div>
                <div class="m_top10">
                    <span class="ent_del">用工时长：</span>
                    <el-select v-model="comParam.workHours" placeholder="请选择" clearable
                    style="width:200px" size="mini">
                        <el-option label="长期工" value="1"></el-option>
                        <el-option label="临时工" value="2"></el-option>
                        <el-option label="钟点工" value="3"></el-option>
                    </el-select>
                </div>
                <div class="m_top10">
                    <span class="ent_del">用工形式：</span>
                    <el-select v-model="comParam.workTypes" placeholder="请选择" clearable
                    style="width:200px" size="mini">
                        <el-option label="劳务派遣工" value="1"></el-option>
                        <el-option label="企业合同工" value="2"></el-option>
                    </el-select>
                </div>
                <div class="m_top10" style="display: flex;align-items: flex-start;">
                    <div class="ent_del">行业分类：</div>
                    <div style="width: 80%;" class="pins">
                        <div>
                            <el-tag v-for="(tag,index) in okInds" :key="tag.name" closable
                            :type="tag.type" @close="closeInds(tag,index)">
                            {{tag.name}}
                            </el-tag>
                        </div>
                        <span class="span" v-for="(item, index) in finids" @click="changesInds(item)">{{item.name}}</span>
                    </div>
                </div>
                <!-- <div class="m_top10">
                    <span class="ent_del">性别：</span>
                    <el-input placeholder = "请" style="width: 150px" size="mini"></el-input>
                </div> -->
            </main>
        </el-drawer>
    </div>
</template>
<script>
import Table from "./Table";
import Chart from "./Chart";
    export default {
        components:{
            Table,Chart
        },
        data() {
            return {
                switchVale:false,
                sechInfo: false,
                direction: 'rtl',
                
                comName:'Table',
                listData: [],
                finids:[],
                okInds:[],

                salaryArr: this.Util.salaryArr, //薪资待遇数据
                comParam: {
                    page: 1,
                    limit: 10,
                    indtypeId:'',
                    recordCount: 0
                }
            }
        },
        created() {
            this.findpids('0')
        },
        methods: {
            changesInds(item) {
                console.log(item);
                // item.isStyle = !item.isStyle;
                if(this.okInds.length<2){
                    // var strId = '';
                    // strId += (strId?'-':'')+item.id
                    this.comParam.indtypeId = item.id
                    this.okInds.push({
                        name:item.name,
                        pid:item.pid,
                        id:item.id
                    })
                    this.findpids(item.id)
                }
            },
            closeInds(e,index) {
                this.okInds.splice(index,1)
                if(index > 0) {
                   let id = this.okInds[index-1].id
                   this.comParam.indtypeId = id
                   this.findpids(id)
                }else {
                   this.findpids('0')
                   this.comParam.indtypeId = ''
                }
            },
            findpids(pid) {
                var _this = this;
                axios({
                    url: this.API.industry.findByPid,
                    method: 'get',
                    headers:{
                        token:_this.Util.getjwtToken(),
                    },
                    params: {
                        id:pid
                    }
                }).then((e) => {
                    let temp = e.data.data;
                    console.log("行业", temp);
                    if(e.data.success){
                        for(var i=0;i<temp.length;i++){
                            temp[i].isStyle = false
                        }
                        this.finids = temp
                    }else{
                        this.finids = []
                    }
                })
            },
            chanittIttm(e) {
                if(e) {
                    this.comParam.ittminSalary = this.salaryArr[e].min;
                    this.comParam.ittmaxSalary = this.salaryArr[e].max;
                } else {
                    this.comParam.ittminSalary = '';
                    this.comParam.ittmaxSalary = '';
                }
            },
            chanSalary(e) {
                if(e) {
                    this.comParam.minSalary = this.salaryArr[e].min;
                    this.comParam.maxSalary = this.salaryArr[e].max;
                } else {
                    this.comParam.ittminSalary = '';
                    this.comParam.ittmaxSalary = '';
                }
            },
            tabChange(e) {
               if(e){
                    this.comName = 'Chart'
               } else {
                    this.comName = 'Table'
               }
            }
        }
    }
</script>
<style lang="scss" scoped>
.people_main{
   height:100%;
   padding:0 15px 15px;
   background:#fff;
}
.finds{
    padding: 15px;
}
.pins .span{
    margin:10px; 
    line-height: 25px;
    cursor: pointer;
    &:hover{
        color: #409eff;
    }
}
.ent_del{
    display: inline-block;
    width: 90px;
}
.color{color: red !important;}
</style>