<template>
    <div>
        <div class="home_box">
            <div class="map_box">
                <el-row :gutter="30">
                    <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6" class="">
                        <h2 class="title">投递人数最多的岗位</h2>
                        <div id="jis1"></div>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6" class="">
                        <h2 class="title">最热门的企业</h2>
                        <div id="jis2"></div>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6" class="">
                        <h2 class="title">最热门的职位</h2>
                        <div id="jis3"></div>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6" class="">
                        <h2 class="title">最热门的行业</h2>
                        <div id="jis4"></div>
                    </el-col>
               </el-row>
               <el-row>
                   <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                        <div id="jis5"></div>
                   </el-col>
               </el-row>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {

            }
        },
        created() {
            
        },
        mounted() {
            this.deliverys();
            this.hotEnts();
            this.hotjobs();
            this.hotjobsInds();
            this.contrast();
        },
        methods: {
            deliverys() {
                var _this = this;
                axios({
                    url: this.API.general_statistics.applyTopMoreJobs,
                    method: 'get',
                    headers:{
                        token:_this.Util.getjwtToken(),
                    },
                    params: {
                        limit: 6
                    }
                }).then((e) => {
                    var resp = e.data;
                    // console.log("图表1", resp);
                    if(resp.success){
                        _this.$chart.deliverys('jis1',resp.data);
                    }else{
                        _this.$chart.deliverys('jis1',[]);
                    }
                })
            },
            //最热门的企业
            hotEnts() {
                var _this = this;
                axios({
                    url: this.API.general_statistics.hotTopMoreCompany,
                    method: 'get',
                    headers:{
                        token:_this.Util.getjwtToken(),
                    },
                    params: {
                        limit: 6
                    }
                }).then((e) => {
                    var resp = e.data;
                    // console.log("图表2", resp);
                    if(resp.success){
                        _this.$chart.hotEnts('jis2',resp.data);
                    }else{
                        _this.$chart.hotEnts('jis2',[]);
                    }
                })
            },
            //最热门的职位
            hotjobs() {
                var _this = this;
                axios({
                    url: this.API.general_statistics.hotTopMoreJobs,
                    method: 'get',
                    headers:{
                        token:_this.Util.getjwtToken(),
                    },
                    params: {
                        limit: 6
                    }
                }).then((e) => {
                    var resp = e.data;
                    // console.log("图表3", resp);
                    if(resp.success){
                        _this.$chart.hotjobs('jis3',resp.data);
                    }else{
                        _this.$chart.hotjobs('jis3',[]);
                    }
                })
            },
            //最热门的行业
            hotjobsInds() {
                var _this = this;
                axios({
                    url: this.API.general_statistics.hotTopMoreIndustry,
                    method: 'get',
                    headers:{
                        token:_this.Util.getjwtToken(),
                    },
                    params: {
                        limit: 6
                    }
                }).then((e) => {
                    var resp = e.data;
                    // console.log("图表4", resp);
                    if(resp.success){
                        _this.$chart.hotjobsInds('jis4',resp.data);
                    }else{
                        _this.$chart.hotjobsInds('jis4',[]);
                    }
                })
            },
            // 同期对比
            contrast() {
                var _this = this;
                axios({
                    url: this.API.general_statistics.contrast,
                    method: 'get',
                    headers:{
                        token:_this.Util.getjwtToken(),
                    },
                    params: {
                        limit: 6
                    }
                }).then((e) => {
                    var resp = e.data;
                    // console.log("图表5", resp);
                    if(resp.success){
                        _this.$chart.contrast('jis5',resp.data);
                    }else{
                        _this.$chart.contrast('jis5',[]);
                    }
                })
            }
        }
    }
    
</script>
<style lang="scss" scoped>
#jis1,#jis2,#jis3,#jis4{
   width: 100%; 
   height: 250px;
}
#jis5 {
   width: 100%;
   height: 600px;
   background:#f6f7f6;
}
</style>