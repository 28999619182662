<template>
    <div>
        <main class="people_main">
            <el-table
                :data="listData"
                stripe
                border
                style="width: 100%;margin-bottom: 20px" >
            <el-table-column
                prop="jobname"
                label="职位名称"
                width="180">
            </el-table-column>
            <el-table-column
                prop="indtypeNames"
                label="行业类型"
                width="180">
            </el-table-column>
            <el-table-column
                prop="entname"
                label="企业名称">
            </el-table-column>
            <el-table-column
                width="200"
                label="新资待遇">
                <template slot-scope="scope">
                    <span v-if="scope.row.minSalary==-1&&scope.row.maxSalary==-1">不限</span>
                    <span v-else-if="scope.row.minSalary==-1">{{scope.row.maxSalary}}元以下</span>
                    <span v-else-if="scope.row.maxSalary==-1">{{scope.row.minSalary}}元以上</span>
                    <span v-else>{{scope.row.minSalary}}-{{scope.row.maxSalary}}元</span>
                </template>
            </el-table-column>
            <el-table-column
                align="center"
                width="200"
                label="投递人数">
                <template slot-scope="scope">
                    <el-popover
                    placement="right"
                    width="100%"
                    trigger="click">
                    <el-table :data="scope.row.jobApplylist">
                        <el-table-column property="username" label="姓名"></el-table-column>
                        <el-table-column label="性别">
                            <template slot-scope="scope">
                                <span v-if="scope.row.sex==1">男</span>
                                <span v-else>女</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="ittcartypeNames" label="期望职业类型" width="180"></el-table-column>
                        <el-table-column property="age" label="年龄"></el-table-column>
                        <el-table-column label="期望新资">
                            <template slot-scope="scope">
                                <span v-if="scope.row.ittminSalary==-1 && scope.row.ittmaxSalary==-1">不限</span>
                                <span v-else-if="scope.row.ittminSalary==-1">{{scope.row.ittmaxSalary}}以下</span>
                                <span v-else-if="scope.row.ittmaxSalary==-1">{{scope.row.ittminSalary}}以上</span>
                                <span v-else>{{scope.row.ittminSalary}}-{{scope.row.ittmaxSalary}}元</span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <span style="cursor: pointer;color: blue;" slot="reference">
                        {{scope.row.jobApplylist.length}}
                    </span>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column
                prop="entname"
                label="工作地点"
                width="200">
                <template slot-scope="scope">
                    <span>{{scope.row.wcityName}}-{{scope.row.wprovinceName}}</span>
                </template>
            </el-table-column>
            </el-table>
            <div class="bom_connt">
                <el-pagination
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :page-sizes="[10,20, 30, 40, 50]"
                    :page-size="10"
                    :current-page="queryform.page"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="queryform.recordCount">
                </el-pagination>
            </div>
        </main>
    </div>
</template>
<script>
    export default {
        name:'Table',
        props: {
            comParam:Object
        },
        data() {
            return {
                listData: [],
                jobApplyData: [],  // 投递人数
                queryform: {
                    page: 1,
                    limit: 10,
                    recordCount: 0
                }
            }
        },
        created() {
            
        },
        watch: {
            comParam:{
                handler: function() {
                    console.log(this.comParam)
                    this.queryform =this.comParam;
                    this.findList()
                },
                deep:true
            } 
        },
        mounted() {
            // console.log('传过来了', this.comParam)
            this.queryform = this.comParam
            this.findList()
        },
        methods: {
            findList() {
                var _this = this;
                axios({
                    url: this.API.general_statistics.jobStatis,
                    method: 'post',
                    headers:{
                        token:_this.Util.getjwtToken(),
                    },
                    data: _this.queryform
                }).then((e) => {
                    var resp = e.data;
                    console.log("统计", resp);
                    if(resp.success){
                        _this.listData = resp.data.list;
                        _this.queryform.recordCount = resp.data.pager.recordCount
                    }else{
                        _this.listData=[];
                    }
                })
            },
            handleSizeChange(e) {
                this.queryform.limit = e;
                this.findList();
            },
            handleCurrentChange(e) {
                this.queryform.page = e;
                this.findList();
            }
        }
    }
</script>
<style lang="scss">




</style>